exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-divorce-index-jsx": () => import("./../../../src/pages/divorce/index.jsx" /* webpackChunkName: "component---src-pages-divorce-index-jsx" */),
  "component---src-pages-glossary-jsx": () => import("./../../../src/pages/glossary.jsx" /* webpackChunkName: "component---src-pages-glossary-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inheritance-index-jsx": () => import("./../../../src/pages/inheritance/index.jsx" /* webpackChunkName: "component---src-pages-inheritance-index-jsx" */),
  "component---src-pages-thankyou-jsx": () => import("./../../../src/pages/thankyou.jsx" /* webpackChunkName: "component---src-pages-thankyou-jsx" */),
  "component---src-templates-location-layout-jsx": () => import("./../../../src/templates/LocationLayout.jsx" /* webpackChunkName: "component---src-templates-location-layout-jsx" */),
  "component---src-templates-post-layout-jsx-content-file-path-content-blog-divorce-attorney-near-you-md": () => import("./../../../src/templates/PostLayout.jsx?__contentFilePath=/opt/render/project/src/content/blog/divorce-attorney-near-you.md" /* webpackChunkName: "component---src-templates-post-layout-jsx-content-file-path-content-blog-divorce-attorney-near-you-md" */),
  "component---src-templates-post-layout-jsx-content-file-path-content-how-it-works-md": () => import("./../../../src/templates/PostLayout.jsx?__contentFilePath=/opt/render/project/src/content/how-it-works.md" /* webpackChunkName: "component---src-templates-post-layout-jsx-content-file-path-content-how-it-works-md" */)
}

